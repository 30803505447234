import { AutocompleteInput, BooleanField, DateField, NumberField, Resource, TextField } from "react-admin";
import { ShoppingBasket, Timer, Category, LocalOffer, Store, Business, ConfirmationNumber, ViewCarousel } from "@material-ui/icons";

import { PlanForm } from "../plan/PlanForm";
import { ActivityForm } from "./category/ActivityForm";
import { LimitedCodeForm } from "./limited_codes/LimitedCodesForm";
import { CategoryList } from "./category/CategoryList";
import { CategoryCreate } from "./category/CategoryCreate";
import { CategoryEdit } from "./category/CategoryEdit";
import { StoreCreate } from "./store/StoreCreate";
import { CompanyList } from "./company/CompanyList";
import { CompanyEdit } from "./company/CompanyEdit";
import { CompanyCreate } from "./company/CompanyCreate";
import { StoreList } from "./store/StoreList";
import { StoreEdit } from "./store/StoreEdit";
import { DiscountList } from "./discount/DiscountList";
import { DiscountCreate } from "./discount/DiscountCreate";
import { DiscountEdit } from "./discount/DiscountEdit";
import { Apps } from "../../enums";
import { fromFields } from "../../components/core/fromFields";
import { VoucherBatchForm } from "./voucher";
import { BannerForm } from "./banner";
import { BannersList } from "../banner/BannerList";
import { fromForm } from "../../components/core/fromForm";

export const discountApp = [
    <Resource
        id="plan"
        options={{ label: "Catalogos", app: Apps.discounts }}
        icon={ShoppingBasket}
        name="plan"
        {...fromFields(PlanForm, [
            { source: "name", field: TextField, label: "Nome" },
        ])}
    />,
    <Resource
        id="category"
        options={{ label: "Categorias", app: Apps.discounts }}
        icon={Category}
        name="category"
        list={CategoryList}
        create={CategoryCreate}
        edit={CategoryEdit}
    />,
    <Resource
        id="company"
        options={{ label: "Empresas", app: Apps.discounts }}
        icon={Business}
        name="company"
        list={CompanyList}
        create={CompanyCreate}
        edit={CompanyEdit}
    />,
    <Resource
        id="store"
        options={{ label: "Lojas", app: Apps.discounts }}
        icon={Store}
        name="store"
        list={StoreList}
        create={StoreCreate}
        edit={StoreEdit}
    />,
    <Resource
        id="discount"
        options={{ label: "Descontos", app: Apps.discounts }}
        icon={LocalOffer}
        name="discount"
        list={DiscountList}
        create={DiscountCreate}
        edit={DiscountEdit}
    />,
    <Resource
        id="limited_code"
        options={{ label: "Códigos Limitados", app: Apps.discounts }}
        icon={Timer}
        name="limited_code"
        {...fromFields(LimitedCodeForm, [
            {
                source: "discount",
                reference: "discount",
                input: (
                    <AutocompleteInput source="discount" optionText="full_name" />
                ),
                field: <TextField source="full_name" />,
                filter: true,
                alwaysOn: true,
                label: "Desconto",
            },
            { source: "code", field: TextField, label: "Código" },
            { source: "used", field: BooleanField, label: "Utilizado?" },
        ])}
    />,
    <Resource
        id="activity"
        options={{ label: "Atividades", app: Apps.discounts }}
        icon={Category}
        name="activity"
        {...fromFields(ActivityForm, [
            { source: "name", field: TextField, label: "Nome" },
        ])}
    />,
    <Resource
        id="banner"
        options={{ label: "Banners", app: Apps.discounts }}
        icon={ViewCarousel}
        name="banner"
        {...fromForm(BannerForm)}
        list={BannersList}
    />,
    <Resource
        id="voucher_batch"
        options={{ label: "Vouchers", app: Apps.discounts }}
        icon={ConfirmationNumber}
        name="voucher_batch"
        {...fromFields(VoucherBatchForm, [
            { source: "name", field: TextField, label: "Nome" },
            { source: "all_vouchers", field: NumberField, label: "Vouchers Criados" },
            { source: "used_vouchers", field: NumberField, label: "Vouchers Usados" },
            { source: "remaining_vouchers", field: NumberField, label: "Vouchers Restantes" },
            { source: "valid_until", field: DateField, label: "Válido até" }
        ])}
    />
]
