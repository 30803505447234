import React from "react";
import { Create } from "react-admin";
import {
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
} from "react-admin";
export const MagazineCreate = (props) => (
    <Create title="Gerar revista" {...props}>
        <SimpleForm {...props}>
            <ReferenceInput
                label="Convênio para gerar a revista"
                source="agreement"
                reference="agreement"
                allowEmpty={true}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
