import { addMinutes } from 'date-fns';
import { useMediaQuery } from "@chakra-ui/react";

export function useCheckMobile() {
  return useMediaQuery("(max-width: 760px)")[0];
}

export function slugfy(text) {
  return text.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll(" ", "")
    .toLowerCase()
    .trim()
}


export function formatDate(date) {
  return addMinutes(date, -date.getTimezoneOffset());
}
