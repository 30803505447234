import {AutocompleteInput, NumberField, Resource, TextField} from "react-admin";
import { Code } from "@material-ui/icons"
import { Apps } from "../../enums";
import { fromFields } from "../../components/core/fromFields";
import {PlanForm} from "./PlanForm";
import {SubscriptionForm} from "./SubscriptionForm";

export const subscriptionApp = [
    <Resource
        id="subscription_plan"
        options={{ label: "Planos", app: Apps.subscriptions }}
        icon={Code}
        name="subscription_plan"
        {...fromFields(PlanForm, [
            {source:"id", field: NumberField, label:"ID"},
            { source: "name", field: TextField, label: "Nome" },
            {source:"access_amount", field:NumberField, label:"Número de Acessos"},
            {
                source: "agreement",
                reference: "agreement",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Convênio",
            },
        ])}
    />,
    <Resource
        id='subscription'
        options={{ label: "Assinatura", app: Apps.subscriptions }}
        icon={Code}
        name="subscription"
        {...fromFields(SubscriptionForm, [
            {
                source: "user",
                reference: "user",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Usuário",
            },
            { source: "cpf", field: TextField, label: "CPF" },
            {
                source: "plan",
                reference: "subscription_plan",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Plano",
            },
        ])}
    />,
    <Resource
        id='subscription_mapintegrationplan'
        options={{ label: "Map de Planos (Integração)", app: Apps.subscriptions }}
        icon={Code}
        name="subscription_mapintegrationplan"
        {...fromFields(SubscriptionForm, [
            {
                source: "user",
                reference: "user",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Usuário",
            },
            { source: "cpf", field: TextField, label: "CPF" },
            {
                source: "plan",
                reference: "subscription_plan",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Plano",
            },
        ])}
    />
]