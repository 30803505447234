import {stringify} from "query-string";
import {axiosInstance} from "../axios";

const getOrdering = (field, order) => {
  return (order === "ASC" ? "" : "-") + field;
};

const getBaseUrl = (resource, action = null, withoutAdminTag) => {
  if (resource === "magazine") {
    if (action === "POST") {
      return `/api/v2/magazine/${resource}_admin`;
    }
    return `/api/v2/magazine/${resource}_admin`;
  }
  if(!withoutAdminTag) {
    return `/api/v1/${resource}_admin`;
  } else {
    return `/api/v1/${resource}`;
  }
};

const sanitizeCPF = (data) => {
  if (data['cpf']) data['cpf'] = data['cpf'].replace(/\./g, "").replace("-", "");
  return data;
};

const separateFiles = (data) => {
  const IGNORE_FIELDS = [
    "cover_photo",
    "image",
    "image_mobile",
    "logo",
    "image_secondary",
    "image_terciary",
    "wallpaper",
    "phone_image",
    "referral_image",
    "cover",
    "file",
    "pdf",
    "audiobooks"
  ];
  const files = {};
  const cleanedData = {};

  Object.entries(data).forEach(([k, v]) => {
    if (k === "audiobooks" && Array.isArray(v)) {
      files[k] = v.map(audiobook => ({
        file: audiobook.rawFile
      }));
    } else if (v && typeof v === typeof {} && v.rawFile != null) {
      files[k] = v.rawFile;
    } else {
      cleanedData[k] = v;
    }
  });

  IGNORE_FIELDS.forEach((f) => {
    delete cleanedData[f];
  });

  return {
    files,
    cleanedData,
  };
};

const dataProvider = {
  getList: (resource, params) => {
    let { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = { ...params.filter };

    if (perPage >= 1000) {
      perPage = 50000;
    }

    if (filter.q) {
      filter.search = filter.q;
      delete filter.q;
    }

    const ordering = getOrdering(field, order);

    const url = `${getBaseUrl(resource)}/?page=${page}&ordering=${ordering}&page_size=${perPage}&${stringify(filter)}`;


    return axiosInstance.get(url)
        .then(({ data }) => {
          if(data?.results) {
            return { data: data.results, total: data.count || 10 };
          }
          return { data: data, total: 10 };
        })
        .catch(error => {
          console.error('Error fetching list:', error); // Debugging line
          throw error;
        });
  },
  getOne: (resource, { id }) => {
    return axiosInstance
        .get(`${getBaseUrl(resource)}/${id}/`)
        .then(({ data }) => {
          return { data };
        });
  },
  getMany: async (resource, { ids }) => {
    return axiosInstance
        .get(`${getBaseUrl(resource)}/?ids=${ids.join(",")}`)
        .then(({ data }) => ({ data: data["results"], total: data["count"] }));
  },
  getManyReference: (resource, { target, id, sort }) => {
    const { field, order } = sort;
    const ordering = getOrdering(field, order);

    return axiosInstance
        .get(`${getBaseUrl(resource)}/&ordering=${ordering}&${target}=${id}`)
        .then(({ data }) => {
          return { data: data["results"], total: data["count"] };
        });
  },
  create: (resource, { data }) => {
    let formData = new FormData();
    let { cleanedData, files } = separateFiles(data);

    cleanedData = sanitizeCPF(cleanedData);

    let magazineForm = new FormData();
    magazineForm.append("agreement", cleanedData.agreement);

    const createResponse = axiosInstance
        .post(`${getBaseUrl(resource, "POST")}/`, resource === "magazine" ? magazineForm : cleanedData);

    if (resource === "magazine") {
      return createResponse;
    }

    return createResponse.then(response => {
      if (Object.keys(files).length > 0) {
        Object.entries(files).forEach(([k, v]) => {
          switch (k) {
            case "audiobooks":
              v.forEach(file => {
                formData.append('audiobooks', file.file);
              });
              break;
            case "cover":
              formData.append('cover', v);
              break;
            case "file":
              formData.append('file', v);
              break;
            default:
              formData.append(k, v);
          }
        });
        return axiosInstance.patch(`${getBaseUrl(resource)}/${response.data.id}/`, formData);
      }
      return response;
    });
  },
  update: (resource, { id, data }) => {
    let formData = new FormData();
    const { cleanedData, files } = separateFiles(data);
    delete cleanedData["plans"];

    const updatePromise = axiosInstance.patch(`${getBaseUrl(resource)}/${id}/`, cleanedData);

    if (Object.keys(files).length > 0) {
      Object.entries(files).forEach(([k, v]) => {
        switch (k) {
          case "audiobooks":
            v.forEach(file => {
              formData.append('audiobooks', file.file);
            });
            break;
          case "cover":
            formData.append('cover', v);
            break;
          case "file":
            formData.append('file', v);
            break;
          default:
            formData.append(k, v);
        }
      });

      return updatePromise.then(() => {
        return axiosInstance.patch(`${getBaseUrl(resource)}/${id}/`, formData);
      });
    }

    return updatePromise;
  },
  updateMany: (resource, params) => { },
  delete: (resource, { id }) => {
    return axiosInstance.delete(`${getBaseUrl(resource)}/${id}/`);
  },
  deleteMany: async (resource, { ids }) => {
    await ids.forEach(async (id) => {
      await axiosInstance.delete(`${getBaseUrl(resource)}/${id}/`);
    });

    Promise.resolve();
  },
};

export default dataProvider;
