// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    MenuItem,
    ListItemIcon
} from '@material-ui/core';
import FolderIcon from "@material-ui/icons/Folder"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"
import { DashboardMenuItem, Menu, MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';

function MenuTreeLinks({ name, icon, resources, onClick, open }) {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <>
            <MenuItem style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "10pt" }} className="RaMenuItemLink-root-43" onClick={() => setOpen(!isOpen)}>
                <ListItemIcon style={{ minWidth: "40px" }}>
                    {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
                </ListItemIcon>
                {name}
            </MenuItem>
            {isOpen ? <div style={{ paddingLeft: "30px" }}>
                {resources.map(resource => (
                    <MenuItemLink
                        style={{ fontSize: "10pt" }}
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onClick}
                        sidebarIsOpen={open}
                    />
                ))}
            </div> : <></>}
        </>
    )
}

export const MyMenu = (props) => {
    const [resourceTree, setResourceTree] = React.useState({ 'main': [] });
    const resources = useSelector(getResources);
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    React.useEffect(() => {
        const newResourceTree = { 'main': [] };
        resources.forEach(resource => {
            const app = resource.options.app;

            if (!app) return newResourceTree.main.push(resource)
            if (!(app in newResourceTree)) newResourceTree[app] = [];


            newResourceTree[app].push(resource)
        })

        setResourceTree(newResourceTree)
    }, [resources])

    return (
        <Menu style={{ width: "200px", backgroundColor: "#eee", borderRight: "1px solid #ddd", minHeight: "100%", margin: 0, paddingTop: "10px", overflowY: "auto" }} {...props}>
            <DashboardMenuItem style={{ fontSize: "10pt" }} />
            {Object.entries(resourceTree).filter(([k, _]) => k !== "main").map(([name, resources]) => (
                <MenuTreeLinks open={open} onClick={props.onMenuClick} name={name} resources={resources} />
            ))}
            {resourceTree.main.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                    style={{ fontSize: "10pt" }}
                />
            ))}
            <MenuItemLink
                to={`/books-dashboard`}
                primaryText={"Books Dashboard"}
                leftIcon={<DefaultIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
                style={{ fontSize: "10pt" }}
            />
            {/* add your custom menus here */}
        </Menu>
    );
};